import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  description,
  row,
  col6,
  col12,
  // marginTopSubstitute,
  hubSpotForm,
} from "../subpage.module.scss";

const KundeneventAnmeldungPage = () => {
  return (
    <Layout pageTitle="Anmeldung Kundenevent" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container neutralPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>Hamburg, 28.-29. Juni 2022</p>
                <h1>the i-engineers besuchen Hamburg</h1>
                <p className="text-medium">
                Wir laden Sie herzlich zu unserem nächsten Kundenevent ein. Digitale Erkenntnisse und eine gute Stimmung sind bei uns vorprogrammiert. Wir freuen uns auf Sie.
                </p>
              </div>

              
            </div>

            <div className={row}>
              <div className={col6}>
                <h3>
                  Tag 1 – Di. Nachmittag, 28. Juni 22, Treffpunkt um 13:00&nbsp;Uhr
                </h3>
                <p style={{ marginBottom: 64 }}>
                  Die Veranstaltung findet im <a className="text-orange" href="https://ameroncollection.com/en/hamburg-hotel-speicherstadt">AMERON Hotel in Hamburg</a> statt.
                  Nachdem wir uns dort zu einem gemeinsamen Apéro zusammengefunden haben, präsentieren und diskutieren unsere Kunden Projekte und Lösungen mit der health engine. Zum Abschluss des Tages laden wir Sie zu einem gemütlichen Zusammenkommen bei gutem Essen und Trinken ein. 
                  <span className="text-purple">– Keynote Speaker: Andreas Rygol.</span>
                  
                  
                </p>



                <h3>Tag 1 – Agenda </h3>
                <p>13:00 Uhr <br />
                Treffpunkt und Apéro riche im AMERON Hotel</p>

                <p>13:30 Uhr <br /> Vorstellungsrunde</p>
                <p> 14:15 Uhr <br /> Keynote Speaker - Andreas Rygol <br /> - Impuls 1: Projektpräsentation IAD <br />- Diskussion & Austausch</p>
                <p> 15:45 Uhr <br /> Kaffee Pause</p>
                <p> 16:15 Uhr <br /> - Impuls 2: Projektpräsentation BBT <br /> - Diskussion & Austausch</p>
                <p> 18:00 Uhr <br /> Abendessen</p>
              </div>

              <div className={col6}>
                <h3>Tag 2 – Mi. Vormittag, 29. Juni 22, 08:30 - 12:00&nbsp;Uhr</h3>
                <p style={{ marginBottom: 64 }}>
                Am folgenden Tag geben unsere Experten Ihnen einen Einblick zum Thema Entwicklungs-Roadmap und welche Features Sie von unserer health-engine in Zukunft erwarten können.
                </p>

              
                <h3>Tag 2 – Agenda </h3>
                <p>08:30 Uhr <br />
                Treffpunkt im AMERON Hotel</p>

                <p>09:00 Uhr <br /> Besprechung Kundenthemen <br />- Diskussion & Austausch</p>
                <p> 10:15 Uhr <br /> Kaffee Pause</p>
                <p> 10:45 Uhr <br /> - Entwicklungs-Roadmap</p>
                <p> 12:00 Uhr <br /> Auf Wiedersehen</p> 
              </div>

            </div>


            <div className={row}>
              <div className={col12}>
                <h3>Anmeldung durch Ihren Ansprechpartner oder dieses Anmeldeformular.</h3>
                <div className={hubSpotForm}>
                  <HubspotForm
                    portalId="9456519"
                    formId="4b2f221d-31f0-401f-aa42-d83e307e8802"
                    region="na1"
                    onSubmit={() => console.log("Submit!")}
                    // onReady={(form) => console.log("Form ready!")}
                    onReady={() => {
                      const script = document.createElement("script");
                      script.defer = "defer";
                      script.src =
                        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
                      let jqueryScript =
                        document.getElementsByTagName("script");
                      let src = Array.from(jqueryScript).filter(
                        (item) => item.src === script.src
                      );
                      if (src.length === 0) {
                        document.body.appendChild(script);
                      }
                    }}
                    loading={<p>Formular wird geladen...</p>}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default KundeneventAnmeldungPage;
